import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { BaseLayout } from 'layouts';
import Auth from 'pages/Auth';
import VerifyCode from 'pages/VerifyCode';
import Dashboard from 'pages/Dashboard';
import Patients from 'pages/Patients';
import AddPatient from 'pages/AddPatient';
import RegisterPractice from 'pages/RegisterPractice';
import AddPatientManual from 'pages/AddPatientManual';
import Terms from 'pages/Terms';
import SecurityPrivacyStatement from 'pages/SecurityPrivacyStatement';
import NotFound from 'pages/NotFound';
import PatientHome from 'pages/PatientHome';
import Chat from 'pages/Chat';
import ChatHistory from 'pages/ChatHistory';
import Contacts from 'pages/Contacts';
import MyPractice from 'pages/MyPractice';
import MyProfile from 'pages/MyProfile';
import Scheduler from 'pages/Scheduler';
import PatientReport from 'pages/Reports/Patient';
import Reports from 'pages/Reports';
import Appointments from 'pages/Appointments';
import ContactProfile from 'pages/ContactProfile';
import Invitations from 'pages/Invitations';
import Group from 'pages/Group';
import CreateGroup from 'pages/CreateGroup';
import Onboard from 'pages/Onboard';
import PharmacyOrders from 'pages/Pharmacy';
import Prescribe from 'pages/EPrescribe';
import Coordinate from 'pages/Coordinate';
import Notes from 'pages/Notes';
import NoteComponent from 'pages/Notes/NoteDetails';
import Careplans from 'pages/Careplans';
import CareplanComponent from 'pages/Careplans/CareplanDetails';
import Security from 'pages/Security';
import CallMasking from 'pages/CallMasking';
import Billing from 'pages/Billing';
import AlertsIndex from 'pages/Alerts';
import PatientAlerts from 'pages/Alerts/PatientAlerts';

import ProtectedRoute from 'routes/Protected';
import urls from 'routes/urls';
import { client } from 'graphql/client';
import AuthRedirectProvider from 'contexts/authRedirect';
import AuthProvider from 'contexts/auth';
import SocketProvider from 'contexts/socket';
import TimerProvider from 'contexts/timer';
import ProviderContextProvider from 'contexts/provider';
import ApiQueueProvider from 'contexts/apiQueue';
import CallServiceProvider from 'contexts/call';
import AppointmentServiceProvider from 'contexts/appointment';
import { FabProvider } from 'contexts/fab';
import { NavMenuProvider } from 'contexts/navMenu';
import CallHistory from 'pages/CallHistory';
// import { useFullscreen } from 'utils/hooks';

import theme from './style/theme';

function App() {
  // useFullscreen();

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <AuthRedirectProvider>
            <SocketProvider>
              <AuthProvider>
                <ProviderContextProvider>
                  <ApiQueueProvider>
                    <TimerProvider>
                      <CallServiceProvider>
                        <AppointmentServiceProvider>
                          <FabProvider>
                            <NavMenuProvider>
                              <BaseLayout>
                                <Switch>
                                  <Route path={urls.AUTH} component={Auth} />
                                  <Route
                                    exact
                                    path={urls.VERIFY_CODE}
                                    component={VerifyCode}
                                  />
                                  <Route
                                    exact
                                    path={urls.TERMS}
                                    component={Terms}
                                  />
                                  <Route
                                    exact
                                    path={urls.SECURITY_PRIVACY_STATEMENT}
                                    component={SecurityPrivacyStatement}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CHAT}
                                    component={Chat}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CHAT_HISTORY}
                                    component={ChatHistory}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CONTACTS}
                                    component={Contacts}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.ONBOARD}
                                    component={Onboard}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.PATIENTS}
                                    component={Patients}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.ADD_PATIENT}
                                    component={AddPatient}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.REGISTER_PRACTICE}
                                    component={RegisterPractice}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.DASHBOARD}
                                    component={Dashboard}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.ADD_PATIENT_MANUAL}
                                    component={AddPatientManual}
                                  />
                                  <ProtectedRoute
                                    path={urls.PATIENT_HOME}
                                    component={PatientHome}
                                  />
                                  <ProtectedRoute
                                    path={urls.MY_PRACTICE}
                                    component={MyPractice}
                                  />
                                  <ProtectedRoute
                                    path={urls.MY_PROFILE}
                                    component={MyProfile}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.NOTE}
                                    component={NoteComponent}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.NOTES}
                                    component={Notes}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CAREPLAN}
                                    component={CareplanComponent}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CAREPLANS}
                                    component={Careplans}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CALL_HISTORY}
                                    component={CallHistory}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.PATIENT_ALERTS}
                                    component={PatientAlerts}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.ALERTS}
                                    component={AlertsIndex}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.E_PRESCRIBE}
                                    component={Prescribe}
                                  />
                                  <ProtectedRoute
                                    path={urls.COORDINATE}
                                    component={Coordinate}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.SCHEDULE}
                                    component={Scheduler}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CALL_MASKING}
                                    component={CallMasking}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.BILLING}
                                    component={Billing}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.REPORTS}
                                    component={Reports}
                                  />
                                  <ProtectedRoute
                                    path={urls.PATIENT_REPORT}
                                    component={PatientReport}
                                  />
                                  <ProtectedRoute
                                    path={urls.APPOINTMENTS}
                                    component={Appointments}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CONTACT}
                                    component={ContactProfile}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.INVITATIONS}
                                    component={Invitations}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.GROUP}
                                    component={Group}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.CREATE_GROUP}
                                    component={CreateGroup}
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.PHARMACY_ORDERS}
                                    component={PharmacyOrders}
                                    allowed="pharmacist"
                                  />
                                  <ProtectedRoute
                                    exact
                                    path={urls.SECURITY}
                                    component={Security}
                                  />
                                  <Redirect to={urls.AUTH} />
                                </Switch>
                              </BaseLayout>
                            </NavMenuProvider>
                          </FabProvider>
                        </AppointmentServiceProvider>
                      </CallServiceProvider>
                    </TimerProvider>
                  </ApiQueueProvider>
                </ProviderContextProvider>
              </AuthProvider>
            </SocketProvider>
          </AuthRedirectProvider>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
